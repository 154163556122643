import styled from 'styled-components'
import media from 'styled-media-query'
import BackgroundImage from 'gatsby-background-image'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import { Triangle } from '@styled-icons/ionicons-solid/Triangle'
import { Link } from 'gatsby'

export const AboutWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  `}

  ${media.lessThan('small')`
    grid-template-rows: auto 1fr;
  `}

  .swiper-pagination-bullet {
    background: white;
  }

  .swiper-pagination-bullet-active {
    background: #cfb53b;
  }
`

export const Image = styled(BackgroundImage)`
  height: 100%;
  ${media.lessThan('small')`
    height: 300px;
  `}
`

export const TextWrapper = styled.div`
  padding: 10%;

  ${media.lessThan('medium')`
    padding: 10% 20px;
    order: -1;
  `}

  ${media.lessThan('small')`
    display: none;
  `}
`

export const TextWrapperMobile = styled.div`
  order: -1;

  ${media.greaterThan('small')`
    display: none;
  `}
`

export const AboutAccordion = styled(Accordion)``

export const AboutAccordionItem = styled(AccordionItem)``

export const AboutAccordionItemHeading = styled(AccordionItemHeading)``

export const AboutAccordionButton = styled(AccordionItemButton)`
  background: #cfb53b;
  color: white;
  text-align: left;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border-bottom: 1px solid #373737;
  border-radius: 0;
  outline: none;

  &:hover {
    background-color: #373737;
    transition: 0.35s;
  }
`

export const AboutAccordionItemPanel = styled(AccordionItemPanel)`
  background: #fffcf3;
  padding: 20px;
  animation: fadein 0.35s ease-in;

  ul {
    margin-left: 15px;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const Title = styled.h2``

export const TriangleIcon = styled(Triangle)`
  width: 25px;
  margin-left: 30px;
  transform: rotateY(0deg) rotate(90deg);

  ${media.lessThan('medium')`
    color: #fff;
    width: 20px;
    transform: rotateY(0deg) rotate(180deg);
    float: right;
  `}
`

export const Button = styled(Link)`
  /* background: #cfb53b; */
  color: #cfb53b;
  text-align: right;
  padding: 20px;
  display: block;
  text-decoration: none;
`
